const en = {
  common: {
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirm',
  },
  clusterPage: {
    title: 'Clusters',
    createNewCluster: 'Create New Cluster',
    createConsentFlow: 'Configure Azure clusters',
    configureAzureClusterButtonTooltips: {
      successful: 'Azure deployment completed successfully.',
      inProgress: 'Azure deployment already in-progress.',
      requireAzureOffer: 'Azure deployment requires offer from tackle.',
    },
    secrets: {
      titlle: 'Database Credentials',
      dbConnection: 'DB Connection:',
      dbUsername: 'DB Username:',
      dbPassword: 'DB Password:',
    },
    pendingUpdates: {
      title: 'The cluster has a scheduled update at {date}',
      startupTitle: 'The cluster has a scheduled update at the next cluster startup.',
    },
  },
  knowIncortaBanner: {
    incortaCommunity: 'Incorta Community',
    join: 'Join',
    incortaOverview: 'Incorta Overview Demo',
    watch: 'Watch',
    incortaDocumentation: 'Incorta Documentation',
    freeTraining: 'Free Training',
    start: 'Start',
  },
  clusterServerConfigurationList: {
    title: 'SQL Interface for External BI Tools',
  },
  clustersPage: {
    clusterTypes: {
      incortaCluster: 'Incorta cluster',
      azureCluster: 'Azure cluster',
    },
    clusterCard: {
      premiumCluster: 'Premium Cluster',
    },
    azureCreationClusterCard: {
      header: 'Deploying Azure Environment',
      step: 'Step',
      nextStep: 'Next',
      info: {
        currentVersion: 'Current Version ',
        size: 'Size ',
        region: 'Region ',
        autoSuspend: 'Auto-suspend ',
      },
      footer: {
        status: 'Status ',
        preparing: 'Preparing...',
        working: 'Working...',
        failed: 'Failed.',
        canceled: 'Canceled',
        viewDetails: 'View Details...',
      },
      errorModal: {
        title: 'Azure Cluster Error Details',
        createdAt: 'Created at',
        error: 'Error',
        copyToClipboardBtn: 'Copy To Clipboard',
        okBtn: 'OK',
        copySuccessful: 'Error Copied Successfully',
      },
    },
  },
  consentFlow: {
    formTitle: 'Configure your Azure clusters',
    formSubtitle: "Only one step and you're good to go!",
    envName: 'Environment Name',
    envNamePlaceholder: 'Incorta',
    envNameTooltip:
      "We'll create two clusters (environments) based on this name—one for development and one for production (e.g., Incorta-DEV and Incorta-PROD).",
    envNameRequired: 'Please enter a valid environment name.',
    envNameMinimumCount: 'Name must be at least 3 characters.',
    envNameMaximmumCount: 'Name cannot be longer than 10 characters.',
    azureSubID: 'Azure Subscription ID',
    azureSubIDPlaceholder: '00000000-0000-0000-0000-000000000000',
    azureRG: 'Azure Resource Group Name',
    azureRGPlaceholder: 'Resource Group Name',
    azureRGTooltip: 'Incorta recommends creating the resource group in the same location as the resources region.',
    resourceGroupRequired: 'Please enter your Azure Resource Group Name.',
    resourceRegionSelection: 'Resources Region Selection',
    resourceRegionSelectionPlaceholder: 'Select',
    subIDRequired: 'Please enter your Azure Subscription ID.',
    subIDValidRequired:
      'Please enter a valid subscription ID. It must match the format 00000000-0000-0000-0000-000000000000 and it only accepts alphanumeric characters.',
    subCredRequired: 'Please enter Azure Subscription Information Output.',
    azureSubscriptionInfoOutputPlaceholder: 'Paste output here',
    resourceRegionSelectionRequired: 'Please select a Resource Region.',
    azureCloudShell: 'Azure Cloud Shell',
    commandRequiredGoTo: 'Go to ',
    commandRequiredRun: ' and run the following command: ',
    commandRequiredTooltip:
      'Copy the following command and run it on {azureCloudShell}, then paste the output under Azure subscription information.',
    spCommand: `az ad sp create-for-rbac --name IncortaSP-<Environment name> --role owner --scopes /subscriptions/<subscription_id>/resourceGroups/<resourcegp_name>`,
    azureCommandCopyTooltip: 'Copy Command',
    azureCommandCantCopyTooltip:
      'In order to copy, please make sure to enter a valid environment name, subscription ID, and resource group name.',
    azureCommandCopySuccessful: 'Azure Command has been copied successfully.',
    networks: 'Networks',
    networksTooltip: 'Use one of the existing networks you have, or let Incorta provide a new network.',
    newNetwork: 'New Network',
    existingNetwork: 'Existing Network',
    networkName: 'Network Name',
    networkNameTooltip: 'Provide the name of your existing virtual network',
    networkNameRequired: 'Please enter a Network Name.',
    addYourNetworkSubnets: 'Add your Network Subnets',
    subnets: {
      subnetRequiredAsterisk: '* ',
      incortaSubnet: 'Incorta Subnet',
      subnetPlaceholderPrefix: 'Example: ',
      incortaSubnetPlaceholder: '172.16.0.0/14',
      sparkSubnet: 'Spark Subnet',
      sparkSubnetPlaceholder: '172.20.0.0/14',
      publicSubnet: 'Public Subnet',
      publicSubnetPlaceholder: '172.24.0.0/24',
      databaseSubnet: 'Database Subnet',
      databaseSubnetPlaceholder: '172.24.1.0/24',
      loadbalancerSubnet: 'Load Balancer Subnet',
      loadbalancerSubnetPlaceholder: '172.24.2.0/24',
    },
    networkSubnetsRequired: 'Please complete the Network Subnets',
    networkConnectionSuccessful: 'Network Connection Test successful',
    networkConnectionFailed: 'Network Connection Test failed',
    cancelButton: 'Cancel',
    submitButton: 'Submit',
    submittingStatus: {
      validating: 'Validating...',
      validationSuccessful: 'Validation successful',
    },
    submissionSuccess:
      'Deploying Incorta on your Azure subscription has started. Please wait 45-50 minutes for your clusters creation.',
    submissionError: 'Something went wrong. Please try again.',
  },
  clusterForm: {
    clusterPlatformField: {
      label: 'Cluster Deployment',
      placeholder: 'Select Your Platform',
      requiredMessage: 'Please select your Platform',
      incortaSaasCloud: 'Incorta SaaS Cloud (GCP)',
      incortaSaasCloudAzure: 'Incorta SaaS Cloud (AZURE)',
      selfHostedIncortedOnAzure: {
        mainText: 'Self-hosted Incorta on Azure',
        configureFirst: '. (Please Configure Azure Clusters first)',
        waitForConfigure: '. (Please Wait for Configuring Azure Clusters to be finished)',
      },
    },
    clusterName: 'Cluster Name',
    clusterNameHint: {
      gc: 'Cluster name can be a minimum of 3 characters long (Alphabet english letters, numbers, dashes or combination), no spaces are allowed, can’t start with numbers and can’t begin or end with dash. Example: incorta-101.',
      azure:
        'Cluster name can be a minimum of 3 characters long (English lowercase alphanumerics) and maximum of 8 characters.',
    },
    clusterNameRequired: 'Please input your Cluster Name!',
    clusterNamePlaceholder: 'Incorta-101',
    clusterDiskSizeLabel: 'Disk Size',
    clusterDiskSizeRequired: 'Please input your Disk Size',
    clusterDiskSizePlaceholder: 'Disk Size',
    clusterSizeLabel: 'Cluster Size',
    clusterSizeOptimizationLabel: 'Cluster Configuration Optimization',
    clusterSizeRequired: 'Please select your Size!',
    clusterSizeUnitGB: 'GB',
    clusterSizeExtra: `IPU refers to Incorta Power Units. To request an increase for these limits. {supportLink}.`,
    clusterVersionLabel: `Incorta Version`,
    clusterRegionLabel: `Cluster Region`,
    clusterRegionDefaultOption: {
      azure: 'Already Configured',
    },
    clusterVersionRequired: 'Please select your Incorta Version!',
    clusterRegionRequired: 'Please select your Cluster Region!',
    clusterRegionUpgradeToPermium: 'Upgrade to a Premium account to create clusters in other regions',
    clusterVersionPlaceholder: 'Select your Incorta Version',
    clusterRegionPlaceholder: 'Select your Cluster Region',
    clusterTenantLabel: 'Tenant Content',
    clusterTenantRequired: 'Please select your Tenant Content!',
    clusterTenantPlaceholder: 'Select your Tenant Content',
    clusterDataAppLabel: 'Data Application Tenant',
    clusterDataAppPlaceholder: 'Select Data Application',
    clusterDataAppHint: 'The selected data application will be installed automatically with the created cluster',
    clusterOptimizationHint: {
      analytics:
        'Mainly used for analytics-intensive workloads, the analytics size will be bigger than the loader size',
      loader: 'Mainly used for loader-intensive workloads, the loader size will be bigger than the analytics size',
      balanced: 'Usually for common workloads, the analytics and loader are equally in size',
    },
    clusterInstallationLabel: 'Installation',
    clusterInstallationRequired: 'Please select Incorta installation type!',
    clusterInstallationPlaceholder: 'Select your Installation',
    clusterAliveLabelTitle: 'Keep alive',
    clusterAliveLabelHint: 'After a period of inactivity the cluster will sleep, turn on to keep it alive.',
    clusterPythonPackagesLabel: 'REQUIRED PYTHON PACKAGES',
    clusterPythonPackagesExtra: 'Should only contain Python packages which are compatible with pip.',
    clusterPythonPackagesPlaceholder: '"Ex: pandas, numpy"',
    clusterCreateButton: 'Create',
    clusteCancelButton: 'Cancel',
  },
  region: {
    'asia-east1': 'Asia-East1 (Taiwan)',
    'asia-east2': 'Asia-East2 (Hong Kong)',
    'asia-northeast1': 'Asia-Northeast1 (Tokyo)',
    'asia-northeast2': 'Asia-Northeast2 (Osaka)',
    'asia-northeast3': 'Asia-Northeast3 (Seoul)',
    'asia-south1': 'Asia-South1 (Mumbai)',
    'asia-south2': 'Asia-South2 (Delhi)',
    'asia-southeast1': 'Asia-Southeast1 (Singapore)',
    'asia-southeast2': 'Asia-Southeast2 (Jakarta)',
    'australia-southeast1': 'Australia-Southeast1 (Sydney)',
    'australia-southeast2': 'Australia-Southeast2 (Melbourne)',
    'europe-central2': 'Europe-Central2 (Warsaw)',
    'europe-north1': 'Europe-North1 (Finland)',
    'europe-west1': 'Europe-West1 (Belgium)',
    'europe-west2': 'Europe-West2 (London)',
    'europe-west3': 'Europe-West3 (Frankfurt)',
    'europe-west4': 'Europe-West4 (Netherlands)',
    'europe-west6': 'Europe-West6 (Zurich)',
    'northamerica-northeast1': 'Northamerica-Northeast1 (Montréal)',
    'northamerica-northeast2': 'Northamerica-Northeast2 (Toronto)',
    'southamerica-east1': 'Southamerica-East1 (São Paulo)',
    'us-central1': 'US-Central1 (Iowa)',
    'us-east1': 'US-East1 (South Carolina)',
    'us-east4': 'US-East4 (N. Virginia)',
    'us-west1': 'US-West1 (Oregon)',
    'us-west2': 'US-West2 (Los Angeles)',
    'us-west3': 'US-West3 (Salt Lake City)',
    'us-west4': 'US-West4 (Las Vegas)',
  },
  auth0: {
    emailSent: {
      pageTitle: 'Check your inbox!',
      matchingEmail:
        'We just sent you an email to verify your email address. Select the button in the email, and you’re all set!',
      cantFindEmail: 'Can’t find it? ',
      resendEmail: 'Resend the email',
      or: ' or ',
      contactSupport: 'Contact incorta Support',
      returnToHomePage: 'Return to Homepage',
    },
    errorPages: {
      general: {
        returnToLoginPageButton: 'Return back to Login Page',
      },
      blockedUser: {
        headerLine1: 'Your account is blocked!',
        headerLine2: ' ',
        contactSupport: 'Contact us',
        subheader: '  to unblock it.',
      },
      trialExpired: {
        headerLine1: 'Thank You!',
        headerLine2:
          "We've received your request and will review it shortly. Our team will reach out to you with the next steps.",
        contactSupport: 'Got further question?',
        subheader: "Please don't hesitate to reach out for our support team.",
      },
      somethingWentWrongPage: {
        headerLine1: 'Something Went Wrong!',
        headerLine2: ' ',
        contactSupport: 'Contact us',
        subheader: '  for support.',
      },
      personalEmailPage: {
        headerLine1: 'Sign Up Failed!',
        subheader1: 'You can’t sign up here with a personal email address.',
        subheader2: 'Use a work email address.',
      },
      mfaRequired: {
        headerLine1: 'Multifactor authentication required',
      },
    },
  },
  premiumPackage: {
    premiumPackageOnlyFeature: 'Upgrade to Premium to allow this feature',
  },
  clusterConfiguration: {
    general: {
      clusterMustBeConnectedToChange: 'Your cluster must be connected to apply this change',
      actionNotSupportedInCurrentPlan: {
        message: "{action} isn't supported with your current plan.",
        clone: 'Clone',
        clusterResize: 'Cluster resize',
        dataApps: 'Data Apps',
        whiteList: 'WhiteList',
        autoSuspend: 'Auto Suspend',
        sqli: 'Sqli',
        dataAgent: 'Data Agent',
        mlFlow: 'MLflow',
        scaling: 'Scaling',
        pythonPackages: 'Python Packages',
        libraries: 'Libraries',
        upgrade: 'Upgrade',
        subcluster: 'Self-Managed Cluster',
      },
    },
    mainTab: {
      premium: {
        tag: 'Premium',
        tagTooltip:
          'Premium cluster, providing access to advanced features such as Incorta Copilot, Business User Notebook, and Data Studio.',
        tagTooltipWithoutCopilot:
          'Premium cluster, providing access to advanced features such as Business User Notebook, and Data Studio.',
        upgradeToPremium: 'Upgrade to Premium',
        upgradeToPremiumModal: {
          title: 'Upgrade to Incorta Premium',
          contentIntro: 'Upgrading to Incorta Premium will unlock many functionalities.',
          contentLearnMore: ' Learn more',
          note: 'Note: ',
          contentOutro: 'This requires an additional license. Please contact your executive to upgrade.',
          cancel: 'Close',
        },
      },
      update: {
        modalTitle: 'Upgrade',
        byBuildNumber: 'By Build Number',
        buildNumberPlaceholder: 'Incorta-20200601-0949-6111',
        byOfficialRelease: 'By Official Release',
        officialReleaseSelectPlaceholder: 'Select Official Release',
        releaseLabel: 'Release {image} ',
        releaseLatest: '(Latest)',
        releaseWhatsNew: 'What’s New?',
        cancelButton: 'Cancel',
        confirmButton: 'CONFIRM',
        confirmButtonDisabledNoBuildNumber: 'Please enter a build number',
        confirmButtonDisabledNoOfficialReleases: 'No official releases available',
        confirmMessage: 'Are you sure you want to save and overwrite this changes?',
        confirmModal: {
          ok: 'CONFIRM CHANGES',
          cancel: 'Cancel',
        },
      },
    },
    configurationsTab: {
      upgradeRequired: 'You need to upgrade your plan in order to upgrade to this size.',
      disabledClusterSize: 'This size can be requested on demand, Please contact the support for further assistance.',
      scaling: {
        selectChart: 'Select Chart',
        scalingHistory: 'Auto Scaling History',
        chartType: '{representation} chart',
        selectDuration: 'Select Duration',
        analytics: 'Analytics Nodes',
        loader: 'Loader Nodes',
        autoScalingSelect: 'Auto Scaling',
        autoScaling: {
          parameters: {
            title: 'Auto Scaling Configurations',
            requestDelay: 'Request Delay: ',
            coolDelay: 'Cooldown delay: ',
            rejectedQueriesUp: 'Rejected queries upscale: ',
            rejectedQueriesDown: 'Rejected queries downscale: ',
            consecutiveQueries: 'Consecutive queries: ',
            units: {
              requestDelay: ' sec',
              coolDelay: ' min',
            },
          },
          legendFormat: 'Replica ',
          rejectedQueriesLegendFormatUnit: ' rejected queries',
          totalQueriesLegendFormatUnit: ' total queries',
          serviceReplicasLegendFormatUnit: ' replicas',
          tooltipFormat: {
            firstLine: 'Replica ',
            secondLine: 'Rejected Queries: ',
            thirdLine: 'Start Time: ',
          },
          xAxis: 'Time',
          yAxis: {
            queries: 'Queries',
            services: 'Services',
          },
          autoScalingHistory: 'Scaling',
        },
      },
      deltaSharing: {
        configurationsDescription1:
          'Enable this toggle to open secure sharing of Incorta’s Delta Lake data stored in GCS buckets.',
        configurationsDescription2: 'Follow ',
        configurationsDescription3: 'these steps ',
        configurationsDescription4: ' for configurations',
        disabled: 'Delta Sharing has been disabled, Please contact support.',
        unsupportedVersion:
          'Unlock Delta Sharing: Upgrade your cluster today for access. Enhance your data capabilities now!',
        modal: {
          copyWheelFileURL: 'Copy Wheel File URL',
          wheelFileURLCopySuccessful: 'Wheel File URL has been copied successfully.',
          copyWheelJSON: 'Copy JSON',
          copyInstallCommandWheelFile: 'Copy Command',
          installCommandWheelFile: 'Command copied successfully',
          jSONCopySuccessful: 'JSON has been copied successfully.',
          copySampleCode: 'Copy Sample Code URL',
          sampleCodeCopySuccessful: 'Sample Code URL copied successfully',
          headerFollowInstructions1:
            'Follow the steps below to set up a delta sharing. For more information, visit the ',
          headerFollowInstructions2: 'documentation ',
          headerFollowInstructions3: 'on Delta Sharing.',
          step1: {
            header: 'Download wheel file',
            description: 'Download the following wheel file to be able to use delta sharing',
          },
          step2: {
            header: 'Install the wheel',
            description:
              'In your preferred IDE/external notebook, install the downloaded wheel by using the following command',
          },
          step3: {
            header: 'Setup the client credentials',
            description: 'Use the following json snippet to authenticate while upgrading the necessary fields',
            replaceJSONVariables: 'Replace PERSONAL_ACCESS_TOKEN and TENANT_NAME with yours.',
          },
          step4: {
            header: 'Sample Code',
            description: 'Download the following Notebook ',
          },
        },
      },
      dataAgent: {
        hint: 'Data agent allows Incorta to connect to on-premise data sources, which may be protected behind a firewall that blocks Incorta from connecting to the data source directly.',
        dataAgentConnectionsTable: {
          title: 'Data Agents Connections',
          refetchData: 'Refresh',
          addTooltip: 'Add new Data Agent Connection',
          noDataAgents: 'No Data Agents',
          errorWhileFetching: 'Error happened while getting Data Agents Connections',
          disconnectedComponent: {
            title: 'Cluster is disconnected',
            description: 'Your cluster must be connected to view Data Agent Connections',
          },
          column: {
            name: 'Name',
            description: 'Description',
            tenant: 'Tenant',
            version: 'Version number',
            status: 'Status',
          },
          rowAction: {
            generateAuthenticationFile: 'Generate Authentication File',
            start: 'Start',
            stop: 'Stop',
            restart: 'Restart',
          },
          menuAction: {
            edit: 'Edit',
            regenerateAuthenticationFile: 'Regenerate Authentication File',
            delete: 'Delete',
          },
          tag: {
            noAuthFileGenerated: 'N/A',
            connected: 'Connected',
            disconnected: 'Disconnected',
            started: 'Started',
            stopped: 'Stopped',
            unknown: 'Unknown',
            tooltip: {
              dataAgentControllerNotReachabled: 'The DA controller is not reachable.',
              dataAgentDisconnectedServices: 'Data Agent Disconnected Services:',
              dataAgentControllerDisconnectedServices: 'DA Controller Disconnected Services:',
            },
          },
          versionStatus: {
            compatible: 'Outdated version',
            incompatible: 'Incompatible version',
          },
        },
        dataAgentConnectionModal: {
          title: '{action} Data Agent Connection',
          titleCreate: 'Create',
          titleEdit: 'Edit',
          field: {
            name: {
              label: 'Name',
              placeholder: 'Connection name',
              required: 'Name is required',
              invalidDataAgentName: 'Invalid Data Agent Name',
            },
            tenant: {
              label: 'Tenant',
              placeholder: 'Select',
              required: 'Tenant is required',
            },
            description: {
              label: 'Description',
              placeholder: 'Write a description',
            },
          },
          action: {
            cancel: 'Cancel',
            submit: 'Create',
            submitEdit: 'Save',
          },
          message: {
            createSuccessful: 'Data Agent is successfully created',
            updateSuccessful: 'Data Agent is successfully updated',
            deleteSuccessful:
              '{count, plural, one {The data agent {name} is successfully deleted} other {The data agents {names} are successfully deleted}}',
            authFileGeneratedSuccessful: 'Auth File is successfully generated',
            actionSuccessful: 'Action is successfully done',
            startActionSuccessful: 'The data agent {dataAgentName} is successfully started',
            stopActionSuccessful: 'The data agent {dataAgentName} is successfully stopped',
            restartActionSuccessful: 'The data agent {dataAgentName} is successfully restarted',
            actionFailed: 'Action has failed',
            actionBusy: 'Data Agent is busy',
            alreadyStarted: 'Already Started',
          },
          regenerateAuthenticationFileConfirm: {
            title: 'Re-generate Authentication File!',
            content:
              'Re-generating the authentication file will revoke the current data agent’s authentication. Do you want to continue?',
            submitButton: 'Confirm',
          },
          deleteConfirm: {
            titleSingle: 'Delete {dataAgentName}',
            contentSingle: 'Heads up! Deleting {dataAgentName} cannot be undone.',
            submitButton: 'Delete',
            //
            titleSingleCantBeDeleted: 'Cannot delete {dataAgentName}',
            contentSingleCantBeDeleted: 'The selected Data Agent has data sources attached ({dataSources}).',
            submitButtonCancel: 'Close',
            //
            titleMultipleCantBeDeleted: 'Cannot delete Data Agents',
            contentMultipleCantBeDeleted: 'The selected Data Agent(s) have data sources attached.',
            attachedTo: 'attached to',
            multipleSubmit: 'Close',
            //
            titleMultiple: 'Delete the following data agents',
            contentMultiple: {
              labelHeadsUp: 'Heads up! Deleting the following data agents cannot be undone.',
              labelCantDelete:
                '{note} The system cannot delete the following Data Agent(s) because they are attached to data sources.',
            },
          },
        },
      },
    },
    schedulingTab: {
      title: '{status} Scheduled Event',
      new: 'New',
      edit: 'Edit',
      view: 'View',
      actions: {
        connect: 'Connect',
        disconnect: 'Disconnect',
        scaleUp: 'Scale Up',
        scaleDown: 'Scale Down',
      },
      scheduledEventTable: {
        title: 'Events',
        columnLabels: {
          event: 'Action',
          startTime: 'Start Time',
          endTime: 'End Time',
          eventDetails: 'Event Details',
          status: 'Status',
        },
        deleteEvent: 'Delete',
        eventDetailsAnalyticsReplicas: ' Analytics Replicas',
        eventDetailsLoaderReplicas: ' Loader Replicas',
        status: {
          queued: 'Queued',
          success: 'Success',
          failed: 'Failed',
        },
      },
      scheduledEventForm: {
        controls: {
          previous: 'Previous',
          schedule: 'Schedule',
          editSchedule: 'Edit Schedule',
          review: 'Review',
          next: 'Next',
        },
        event: 'Action',
        startTime: 'Start Time',
        startTimeErrorText: 'Please select a date and time after the current',
        scaleStepHint: 'At least set one of Analytics, or Loader Replicas',
        scaleReplicasCounts: {
          current: 'Current Replicas: {currentReplicas}',
          after: ', After Event: {replicasAfterEvent}',
        },
        scaleAnalyticsReplicas: 'Analytics Replicas',
        scaleAnalyticsReplicasPlaceholder: 'Analytics replicas amount for scaling',
        scaleLoaderReplicas: 'Loader Replicas',
        scaleLoaderReplicasPlaceholder: 'Loader replicas amount for scaling',
        endTime: 'End Time',
        endTimeStepOptional: ' (Optional)',
        endTimeStepHint: 'For rollback, after start time',
        summaryStepTitle: 'Event Summary',
        summaryStepAction: 'Action: ',
        summaryStepStartTime: 'Start Time: ',
        summaryStepEndTime: 'End Time: ',
        summaryStepDetails: 'Details',
        summaryStepAnalyticsReplicas: 'Analytics Replicas: ',
        summaryStepLoaderReplicas: 'Loader Replicas:',
        summaryStepResult: 'Event Result: ',
      },
      message: {
        deleteSuccess: 'Event deleted successfully',
        updateSuccess: 'Event update successfully',
        addSuccess: 'Event created successfully',
      },
    },
    packagesTab: {
      title: 'Libraries',
      displayVersions: 'Sync Versions',
      installNewLibraryButton: 'Install new library',
      unInstallLibrariesButton: 'Uninstall Selected',
      filterLibraryPlaceholder: 'Filter Libraries',
      selectedCount: '{count} selected',
      librariesTable: {
        columnLabels: {
          name: 'Library',
          version: 'Version',
          type: 'Type',
          source: 'Source',
          status: 'Status',
          analyticsState: 'Analytics State',
          loaderState: 'Loader State',
          chidoriState: 'Chidori State',
        },
      },
      statusTags: {
        tooltip: {
          analytics: 'Analytics',
          loader: 'Loader',
          spark: 'Spark',
          chidori: 'Chidori',
        },
        status: {
          installing: 'Installing...',
          installed: 'Installed ✓',
          failed: 'Failed ✗',
          uninstalling: 'Uninstalling...',
          uninstalled: 'Uninstalled ✓',
        },
      },
      actionModal: {
        reinstall: 'Reinstall',
        edit: 'Edit',
        editVersion: 'Edit Version',
        uninstall: 'Uninstall',
        userCantUpdate: 'You need higher permission',
        anotherInstallingLibrary: 'There is another library installing, please wait for it',
        anotherUninstallingLibrary: 'There is another library uninstalling, please wait for it',
        title: '{action} Library',
        confirm: 'Are you sure you want to {action} {packageName} of version {packageVersion}?',
        confirmWithNoVersion: 'Are you sure you want to {action} {packageName}?',
        titleUninstallLibraries: 'Uninstall Libraries',
        confirmMultipleUninstall: 'Are you sure you want to uninstall the following libraries?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        libraryToBeInstalled: 'Library will be reinstalled',
        deleteSuccess: 'Library successfully uninstalled',
        multipleDeleteSuccess: 'Libraries successfully uninstalled',
      },
      addPackageModal: {
        title: 'Install Package',
        install: 'Install',
        cancel: 'Cancel',
        librarySource: 'Library Source:',
        radios: {
          pypi: 'PyPI',
          organization: 'Organization',
          gcs: 'File Path / GCS',
          maven: 'Maven',
          cran: 'CRAN',
          fileLoader: 'File Uploader',
        },
        placeholder: 'Type and press Space eg., numpy==1.3',
        hint: 'Only packages that are compatible with pip.',
        confirmModal: {
          title: 'Installed Packages Detected',
          content: 'Please note that {packages} {plural1} already installed, do you want to overwrite {plural2}',
          singular1: 'is',
          plural1: 'are',
          singular2: 'it',
          plural2: 'them',
        },
        tab: {
          pypi: {
            label: 'PyPI Package(s)',
            required: 'Add at least one PyPI Package',
          },
          maven: {
            coordinatesField: 'Coordinates',
            coordinatesFieldRequired: 'Please add Maven Coordinates',
            coordinatesFieldPlaceholder: 'Maven Coordinates (com.yourcompany:spark-csv_2.10:1.0.0)',
            repositoryField: 'Repository',
            repositoryFieldTooltip:
              'The Maven repository serves as the source for installing the Maven package. If not specified, the default Maven repo will be used.',
          },
          cran: {
            packageField: 'Package',
            packageFieldRequired: 'Please add CRAN Package',
            repositoryField: 'Repository',
            repositoryFieldTooltip:
              'The CRAN repository serves as the source for installing the CRAN package. If not specified, the default CRAN repo will be used.',
          },
          file: {
            label: 'File',
            required: 'Please upload a supported file',
            unsupportedFile: 'You can only upload Py, JAR, WHL, or Egg files!',
          },
        },
      },
      editModal: {
        edit: 'Edit',
        title: 'Package Version',
        hint: 'Leave it empty if you want to install the latest version',
        errorLabel: 'Please enter a valid pacakge version e.g. 1, 1.0 or 1.0.0 or 1.0.0.0',
        reinstall: 'Reinstall',
        editVersion: 'Edit Version',
        delete: 'Delete',
      },
    },
    azureSynapse: {
      header: 'Enable Azure Synapse Analytics',
      description: 'Have the option to choose Azure Synapse Analytics as a Data Destination inside Incorta Analytics.',
      button: {
        enableButton: 'Enable',
        installingButton: 'Installing',
        editButton: 'Edit',
      },
      successMessage: 'Azure Synapse enabled successfully',
    },
    azureSynapseModal: {
      title: 'Enable Azure Synapse Analytics',
      tenantFieldLabel: 'Tenant',
      tenantFieldPlaceholder: 'Tenant Name',
      usernameFieldLabel: 'Username',
      usernameFieldPlaceholder: 'Username',
      passwordFieldLabel: 'Password',
      cancelBtn: 'Cancel',
      submitBtn: 'Submit',
      requiredTenant: 'Please select your tenant!',
      requiredUsername: 'Please input your username!',
      requiredPassword: 'Please input your password!',
    },
    incortaX: {
      title: 'Enable Advanced SQL Interface',
      mainHint: 'Connect to Tableau, Power BI, or other SQL tools.',
      moreHint:
        ' Enabling this feature will enforce the Null Handling feature, ignoring the user-defined Null Handling value.',
    },
    copilot: {
      title: 'Advanced Copilot',
      desription:
        'Activating this feature will initiate the Copilot service, enabling advanced capabilities with planning functions. This allows access to features such as the Dashboard Insight Assistant.',
    },
    dataStudio: {
      title: 'Data Studio',
      description:
        'Enable this option to automatically generate PySpark code using a simple drag-and-drop interface. Users can apply transformations to their data using predefined recipes and seamlessly save the transformed data as a new Materialized View (MV).',
    },
    copilotModal: {
      title: 'Copilot',
      enable: 'Enable',
      disable: 'Disable',
      openAIApiKey: 'OpenAI API key',
      requiredApiKey: 'API Key is required',
      cancelBtn: 'Cancel',
      submitBtn: 'Save',
      copilotStateMessage: 'Copilot will be ',
      copilotStateWillBeEnabled: 'enabled',
      copilotStateWillBeDisabled: 'disabled',
    },
    titles: {
      clusterSize: 'Cluster Size',
      clusterServicesControl: 'Services',
      analyticsNode: 'Analytics Node',
      loaderNode: 'Loader Node',
      autoSuspend: 'Auto Suspend',
      clusterRegion: 'Cluster Region',
      homePage: 'Home Page',
      onBoarding: 'On Boarding',
      deltaSharing: 'Delta Sharing',
      copilot: 'Enable Copilot',
      externalBITools: 'External BI Tools',
      incortaX: 'Advanced SQL Interface',
      microsoftSynapse: 'Synapse Analytics',
      dataAgent: 'Data Agent',
      pythonPackages: 'Packages',
      onBoard: 'Enable Onboarding',
      mlFlow: 'MLflow',
      dataStudio: 'Enable Data Studio',
    },
    clusterServicesControl: {
      titles: {
        analytics: 'Analytics',
        loader: 'Loader',
        sqli: 'SQLi',
        notebook: 'Notebook',
        analyticsSubCluster: 'Analytics Self-Managed Clusters',
        loaderSubCluster: 'Loader Self-Managed Clusters',
      },
      serviceNames: {
        analytics: 'Analytics Service {number}',
        loader: 'Loader Service {number}',
        sqli: 'SQLi Service',
        notebook: 'Notebook Service',
        analyticsSubCluster: 'Analytics Self-Managed Cluster {number}',
        analyticsSubClusterFailOver: 'Analytics Failover Self-Managed Cluster {number}',
        loaderSubCluster: 'Analytics Self-Managed Cluster {number}',
      },
      disconnectedComponent: {
        title: 'Cluster is disconnected',
        description: 'Your cluster must be connected to view these services',
      },
      status: {
        processing: 'Processing',
        startingTenants: 'Starting Tenants',
        startingTenant: 'Starting Tenant',
        isStarting: 'Starting',
        running: 'Started',
        unreachable: 'Unreachable',
        sleeping: 'Sleeping',
        stopping: 'Stopping',
        stoppingTenants: 'Stopping Tenants',
        stoppingTenant: 'Stopping Tenant',
        notRunning: 'Stopped',
        error: 'Error',
        startupError: 'Startup Error',
        notResponding: 'Not Responding',
        TerminatedUnexpectedly: 'Terminated Unexpectedly',
        na: 'N/A',
        startedWithWarnings: 'Started with warnings',
        failedToStart: 'Failed to start',
        creating: 'Creating',
        deleting: 'Deleting',
      },
      headerOptions: {
        tooltipCantAddReachedMaxCount: 'You’ve reached maximum number of {serviceType} service nodes',
        tooltipCantAddForTrialUser: 'Only available for Premium users. Chat with us to upgrade.',
        tooltipSubClusterDisconnected:
          'The Self-Managed Cluster is currently disconnected, Connect it firstly to do the action',
        configureAnalyticsSubCluster: 'Configure',
        addServices: 'Add {serviceName} services',
        analytics: 'analytics',
        loader: 'loader',
        options: {
          startAll: 'Start all',
          stopAll: 'Stop all',
          restartAll: 'Restart all',
          deleteServices: 'Delete services...',
        },
        deleteServices: 'Delete {serviceName} services',
        deleteServiceDropdown: {
          note: 'Note: ',
          message: 'You can’t delete the last service.',
        },
        buttons: {
          add: 'Add',
          cancel: 'Cancel',
          delete: 'Delete',
        },
        messages: {
          addDeleteSuccess: '{nodesNumber} {serviceType} service{plural} {operation}',
          operationAdded: 'added',
          operationDeleted: 'deleted',
        },
      },
    },
    // TODO: move / remove
    pythonPackages: {
      subHeader: 'Incorta Machine Learning Libraries are required to be used in the Materialized views.',
      addPackages: {
        title: 'Add Packages',
        placeholder: 'Type and press Space eg., numpy==1.3',
        install: 'Install',
        hint: 'Only packages that are compatible with pip.',
        confirmModal: {
          title: 'Installed Packages Detected',
          content: 'Please note that {packages} {plural1} already installed, do you want to overwrite {plural2}',
          singular1: 'is',
          plural1: 'are',
          singular2: 'it',
          plural2: 'them',
        },
      },
      installedPackages: {
        title: 'Installed Packages',
        displayVersions: 'Sync Versions',
      },
      editModal: {
        edit: 'Edit',
        title: 'Package Version',
        hint: 'Leave it empty if you want to install the latest version',
        errorLabel: 'Please enter a valid pacakge version e.g. 1, 1.0 or 1.0.0 or 1.0.0.0',
        reinstall: 'Reinstall',
        editVersion: 'Edit Version',
        delete: 'Delete',
      },
    },
    mlFlow: {
      connectToMLflow: 'Connect to MLflow Portal.',
      enableConnectMLTools: 'Enable this toggle to connect to machine learning tools.',
    },
  },
  provisionUserModal: {
    title: 'Change Password',
    newPasswordFieldLabel: 'New Password',
    confirmPasswordFieldLabel: 'Confirm Password',
    skipBtn: 'Skip and use current password',
    updateBtn: 'Update Password',
    fillInPasswordErrorMessage: 'Please fill in the password',
    confirmPasswordErrorMessage: 'Please confirm the password',
    passwordsDontMatchErrorMessage: 'Passwords don’t match',
    validatePasswordCriteriaErrorMessage: 'Enter a password that meets the requirements.',
    changeProvisionUserPassword: {
      successMessage: 'Password successfully changed',
    },
    passwordCriteria: {
      minimumLowercaseLetter: '1 lowercase letter',
      minimumUppercaseLetter: '1 uppercase letter',
      minimumNumber: '1 number',
      minimumSpecialCharacter: '1 special character',
      minimumCharacters: '12 characters minimum',
    },
  },
  myAccount: {
    personalInformation: {
      firstNameFieldLabel: 'First Name',
      firstNameFieldPlaceholder: 'First Name',
      firstNameFieldErrorMessage: 'Please input your first name!',
      lastNameFieldLabel: 'Last Name',
      lastNameFieldPlaceholder: 'Last Name',
      lastNameErrorMessage: 'Please input your last name!',
      jobTitleFieldLabel: 'Job Title',
      jobTitleFieldPlaceholder: 'Job Title',
      jobTitleFieldErrorMessage: 'Please input your job title!',
      companyFieldLabel: 'Company',
      companyFieldPlaceholder: 'Company',
      companyFieldErrorMessage: 'Please input your company!',
      phoneFieldLabel: 'Phone',
      phoneFieldPlaceholder: '+0123456789',
    },
  },
  cloneModal: {
    submitButton: 'SUBMIT',
    cancelButton: 'Cancel',
    clonedClusterNameFieldLabel: 'CLONED CLUSTER NAME',
    successMessage: 'cluster is cloned successfully',
  },
  createNewSubClusterModal: {
    description:
      'Set the name, size and type to create your Self-Managed Cluster, all other configurations are inherited from the main cluster.',
    scaleDescription:
      'Easily adjust the resources allocated to your services with this intuitive scaling tool. Use the controls to increase or decrease the number of service instances based on your workload needs.',
    nodesFieldLabel: 'Services',
    nodesFieldHint: 'View the current services count and Apply scaling adjustments with a single click.',
    nameFieldLabel: 'Name',
    nameFieldPlaceholder: 'Incorta-101',
    nameFieldHint: {
      gc: 'Self-Managed Cluster name can be a minimum of 3 characters long (Alphabet english letters, numbers, dashes or combination), no spaces are allowed, can’t start with numbers and can’t begin or end with dash. Example: incorta-101.',
      azure:
        'Self-Managed Cluster name can be a minimum of 3 characters long (English lowercase alphanumerics) and maximum of 8 characters.',
    },
    sizeFieldLabel: 'Size',
    sizeFieldPlaceholder: 'Select a Size',
    typeFieldLabel: 'Type',
    typeFieldConfig: 'Extra Configs',
    typeFieldError: 'Type is required',
    typeFieldPlaceholder: 'Select a Type',
    saveButton: 'Save',
    createButton: 'Create',
    cancelButton: 'Cancel',
    selfManagedClusterNameRequired: 'Please input Self-Managed Cluster Name!',
  },
  supportForm: {
    emailFieldLabel: 'Email',
    emailFieldPlaceholder: 'example@mail.com',
    emailFieldNotValidError: 'The input is not valid Email!',
    emailFieldRequiredError: 'Please input your Email!',
    messageFieldLabel: 'Describe Your ISSUE/SUGGESTION',
    messageFieldPlaceholder: 'Issue/Suggestion Description',
    messageFieldError: 'Please input your message',
    sendButton: 'Send',
    cancelButton: 'Cancel',
  },
  userConsentCookiesText: `The website and API use cookies and other tracking technologies to improve the user experience,
   analyze traffic, personalize content, and serve targeted ads. The statement emphasizes the use of both session and persistent cookies,
    as well as third-party cookies. We collect personal data and process data elements in accordance with applicable data protection laws,
     and users can withdraw their consent by contacting {privacyEmail}. Users are encouraged to contact the website via our global privacy policy or API owner with questions or concerns about cookie usage.
      To opt-in or opt-out of Incorta marketing communication,
     click on this link. this statement is governed by our 
     {globalPrivacy} and 
     {cookiesPolicy}`,
};
export default en;
