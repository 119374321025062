import { LibraryInstallationStateEnum } from './libraries.types';

export enum CSize {
  small = 'small',
  medium = 'medium',
  larges1 = 'larges1',
  larges2 = 'larges2',
  larges3 = 'larges3',
  larges4 = 'larges4',
  larges5 = 'larges5',
  larges6 = 'larges6',
  larges7 = 'larges7',
}

interface Tanent {
  id: number;
  name: string;
  data_size: number;
  requied_cpu: number;
  required_memory: number;
  default: boolean;
  createdAt: string;
  updatedAt: string;
}

interface User {
  id: string;
  fullName: string;
  email: string;
}

export type InstanceBuildProperties = Record<string, string | boolean> | null;

export interface Instance {
  services: Service[];
  lockedActions: string;
  id: string;
  name: string;
  maxIPUs: string | null;
  contractThreshold: number;
  status: string;
  sleeppable: boolean;
  image: string;
  consumption: string;
  historicalBucketMetrics: HistoricalMetrics[];
  analyticsSize: SizeResultItem;
  loaderSize: SizeResultItem;
  csize: CSize;
  csizeDetails: string;
  csize2: CSize;
  csizeDetails2: string;
  dsize: string;
  availableDisk: string;
  cpu: string;
  memory: string;
  ipu: number;
  hostName: string | null;
  runningAt: string | null;
  tenantID: number;
  tenant: Tanent;
  pips: string;
  sqliEnabled: boolean;
  incortaXEnabled: boolean;
  /** The Switch btn "Enable Advanced SQL Interface" in Configurations tab */
  isIncortaXEligible: boolean;
  upgradable: boolean;
  availableUpgrades: string[];
  byBuildNumber: boolean;
  cloneable: boolean;
  remoteStorage: boolean;
  consumedData: string | null;
  releaseCount: string;
  releaseUrl: string;
  tenantUsers: number;
  maxExecutors: number;
  minExecutors: number;
  customBuild: string;
  createdAt: string;
  consumptionAgg: any;
  historicalAgg: any;
  enableDataAgent: boolean;
  dataAgentNewUI: boolean;
  /** analytics connection; will only be returned if dataAgentNewUI is true */
  analyticsDataAgent: string;
  /** loader connection; will only be returned if dataAgentNewUI is true */
  loaderDataAgent: string;
  /** cmc connection; will only be returned if dataAgentNewUI is true */
  cmcDataAgent: string;
  enableMicrosoftSynapse: boolean;
  enableOnboarding: boolean;
  enableChat: boolean;
  importedBlueprints?: ImportedBlueprint[];
  loaderNodes: number;
  analyticsNodes: number;
  externalCMC: boolean;
  corrupted: boolean;
  subClusters: SubCluster[];
  toggleFeatures: any[];
  displayIdleTime: string | null;
  idleTime: number;
  isSqliMigrated: boolean;
  isSqliSeparated: boolean;
  isChidori: boolean;
  enableDeltaShare: boolean;
  clusterPolicy: {
    userPolicy: ('list' | 'create' | 'update' | 'delete')[];
    clusterPolicy: ('list' | 'create' | 'update' | 'delete')[];
    connectionPolicy: ('list' | 'create' | 'update' | 'delete')[];
    cliPolicy: ('list' | 'create' | 'update' | 'delete')[];
    blueprintPolicy: ('list' | 'create' | 'update' | 'delete')[];
    subClusterPolicy: ('list' | 'create' | 'update' | 'delete')[];
    managementPolicy: ('list' | 'create' | 'update' | 'delete')[];
    whitelistPolicy: ('list' | 'create' | 'update' | 'delete')[];
    authorizedRoles: ClusterUserRole[];
    roleName: 'owner' | 'accountAdmin' | 'accountManager' | 'developer';
  };
  clusterScalingNodes: clusterScalingNode[];
  owner: User;
  authorizedRoles: ClusterUserRole[];
  region: string;
  zone: string;
  k8sClusterCode: string;
  platform: string;
  regionAlias: string;
  requireCleanup?: boolean;
  InstanceChidori?: InstanceChidori;
  autoscale: AutoScaleItem | null;
  enableOpenAI: boolean;
  openApiKey: string;
  mlflowEnabled: boolean;
  isPremium: boolean;
  /** from the Admin  */
  enableBusinessUserNotebook: boolean;
  /** build properties from incorta.properties
   * @example 
  {
    "services.singletenant": true,
    "service.separateSqli": true,
    "service.ondemandloader": true,
    "service.sessiontimeout": true,
    "service.require.spark.version": "3.4.1",
    "service.sqlx.require.http": true,
    "service.copilot.version": "2.2.0",
    "service.sqlx.require.custom.jars": true,
    "service.spark.unification.enable": true,
    "service.premium.supported": true
  }
   */
  buildProperties: InstanceBuildProperties;
  premiumFeaturesConfig: string[] | null;
  sqliNullHandling: boolean;
  observabilityNewUI: boolean | null;
}

export interface InstanceChidori {
  id: number;
  instanceID: string;
  isChidori: boolean;
  sparkType: string;
  sparkTypeConfig: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AutoScaleItem {
  id: number;
  enabled: boolean;
  requestDelay: number;
  coolDelay: number;
  rejectedQueriesUp: number;
  rejectedQueriesDown: number;
  consecutiveQueries: number;
  instanceID: string;
}
export interface InstalledPythonPackage {
  id: string;
  analyticsState: LibraryInstallationStateEnum;
  loaderState: LibraryInstallationStateEnum;
  chidoriState?: LibraryInstallationStateEnum;
  name: string;
}

export interface NotificationInfo {
  hasUnRead: boolean;
  notifications: Notification[];
}

export interface Notification {
  createdAt: string;
  event: string;
  eventType: string;
  id: string;
  instanceID: string | null;
  message: string;
  updatedAt: string;
  read: boolean;
}

export enum SelfManagedClusterTypes {
  'sqlx' = 'sqlx',
  'analytics' = 'analytics',
  'fallbackanalytics' = 'fallbackanalytics',
  'chidori' = 'chidori',
}

export interface SubCluster {
  name: string;
  configs: string;
  createdAt: string;
  id: number;
  instanceID: string;
  nodes: number;
  size: string;
  sizeID: number;
  sizeDisplayName: string;
  state: string;
  status: string;
  type: SelfManagedClusterTypes;
  updatedAt: string;
  url: string;
  cpu: number;
  ipu: number;
}
export interface Regions {
  regions: Region[];
}

export interface Region {
  region: string;
  regionAlias: string;
  url: string;
  zone: string;
  cluster_code: string;
  platform: string;
  app_id: string;
  userId?: string;
  isAvailable?: boolean;
}

export interface InstancesData {
  instances: InstanceInfo[];
  notifications?: NotificationInfo;
  status?: number;
}

export interface ClusterDataStudioState {
  enabled: boolean;
}

export interface PremiumPackageAllowed {
  enabled: boolean;
}

export interface PremiumPackageSupported {
  supported: boolean;
}

export interface DataSource {
  datasources: string[];
}

export interface InstanceInfo {
  instance: Instance;
  services: Service[];
  serverConfiguration: ServerConfiguration[];
  incortaXServerConfiguration: ServerConfiguration[];
  mlflowServerConfiguration: ServerConfiguration[];
}

export interface Service {
  name: string;
  status: string;
  url: string;
}

export interface HistoricalMetrics {
  bucketSizeInGB: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  instanceID: string;
}

export interface Library {}

export interface ServerConfiguration {
  name: string;
  text: string;
  description: string;
}

export type ClusterStatus =
  | 'running'
  | 'starting-up'
  | 'stopping'
  | 'corrupted'
  | 'creating'
  | 'sleeping'
  | 'undefined';

export interface GetClusterData {
  userId: string;
  instanceName: string;
}

export interface DeleteClusterData {
  userId: string;
  instanceName: string;
}

export interface Image {
  image: string;
  tags?: {
    release: string;
  };
}

export interface ImagesResult {
  images: Image[];
}

export interface TenantsResult {
  tenants: {
    id: number;
    name: string;
    data_size: number;
    requied_cpu: number;
    required_memory: number;
    default: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
}

export type ClusterTenantsResponse = {
  tenants: {
    name: string;
    id: number;
  }[];
};

export interface Size {
  type: CSize;
  description: string;
  cpu: string;
  ipu: number;
  memory: string;
  isTrialAvailable: boolean;
  isDisabled?: boolean;
}

export type SizeResultItem = {
  id: number;
  name: string;
  displayName: string;
  nodeLabel: string;
  regionCloudProviderID: number;
  memoryRequest: number;
  memoryLimit: number;
  memoryText: number;
  ipu: number;
  cpu: number;
  icc: number;
  order: number;
  canBeDeleted: boolean;
  isCustomSize: boolean;
  overCommitment: string;
  createdAt: string;
  updatedAt: string;
};
export interface SizesResult {
  sizes: SizeResultItem[];
  allSizes: SizeResultItem[];
}

export type SubclusterSizesResultItem = {
  type: string;
  description: string;
  cpu: number;
  ipu: number;
  memory: number;
  displayName: string;
};
export type SubclusterSizesResult = {
  sizes: SubclusterSizesResultItem[];
  allSizes: SubclusterSizesResultItem[];
};

export enum OptimizationLevel {
  Analytics = 'Analytics Optimized',
  Balanced = 'General Purpose',
  Loader = 'Loader Optimized',
}

export type ClusterFormData = {
  name: string;
  csize: SizeResultItem;
  dsize?: number;
  image?: string;
  tenantID?: number;
  installation?: string;
  alive?: boolean;
  sleeppable?: boolean;
  pips?: string;
  enableDataAgent?: boolean;
  idleTime?: number;
  region?: any;
  zone?: string;
  k8sClusterCode?: string;
  appId?: string;
  enableOnboarding?: boolean;
  optimizationLevel?: OptimizationLevel;
  blueprintId?: number;
};

export interface Subscription {
  k8sVersion: any;
  incortaImage: any;
  token: any;
  configurationName: string;
  servicePrincipalCredentials: string;
  subscriptionID: string;
  resourceGroup: string;
  appID: string;
  password: string;
  tenant: string;
  network: boolean;
  vnName: string;
  subnet1: string;
  subnet2: string;
  subnet3: string;
  subnet4: string;
  subnet5: string;
  region: string;
  userId: string;
  email: string;
  plan: string;
}

interface EditInstanceData {
  pips: string;
  csize: CSize;
}

export interface ChangeSqliStateResult {
  message: string;
}

export interface MessageResult {
  message: string;
}

export interface ClusterUpgradeResult {
  message: string;
}

export interface BlueprintItem {
  blueprintCategoryID: number;
  businessFunction: string;
  createdAt: string;
  exportedFile: string;
  id: number;
  name: string;
  position: number;
  updatedAt: string;
  url: string;
  selected?: boolean;
  blueprintCategory: BlueprintCategory;
  type: string;
  tag: string;
  description: string;
  slug?: string;
  installable?: boolean;
  minimal_version?: string;
  trial?: false;
}

export interface BlueprintsResult {
  blueprints: BlueprintItem[];
  blueprintFunctions: string[];
  blueprintTypes: string[];
}

export interface ImportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
}

export interface ExportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
  exportFolder: string;
}

interface ImportBlueprintValues {
  tenant: string;
  username: string;
  password: string;
  loadFromStaging: boolean;
}

export interface ImportedBlueprint {
  id: number;
  instanceID: string;
  blueprintID: number;
  tenant: string;
  imported: boolean;
  createdAt: string;
  updatedAt: string;
  blueprint: {
    id: number;
    name: string;
    url: string;
    position: number;
    exportedFile: string;
    businessFunction: string;
    blueprintCategoryID: number;
    blueprintCategory: BlueprintCategory;
    createdAt: string;
    updatedAt: string;
  };
}

export interface importedBlueprintsObj {
  [key: string]: ImportedBlueprint[];
}
export interface ImportState {
  state: 'loading' | 'success' | 'failed';
  importedBlueprints: importedBlueprintsObj;
  datasourceMessage?: string;
  failedBlueprints?: ImportedBlueprint['blueprint'][];
  message?: { title?: string; body?: string };
  error?: string;
}

interface BlueprintCategory {
  id: number;
  image: string;
  name: string;
  updatedAt: string;
  createdAt: string;
}

export type TableData = {
  day: Date;
  dayFormat: string;
  incorta: any;
  spark: any;
  total: any;
  isBeforeNow: boolean;
  hours: {
    hour: number;
    incorta: any;
    spark: any;
  }[];
}[];

export interface ConsumptionInfo {
  data: {
    clusters: {
      name: string;
      id: string;
      consumptionAgg: any;
      historicalAgg: any;
    }[];
  };
}

export interface ValidBlueprintClusters {
  instances: Instance[];
}

export interface SupportToken {
  token: string;
  expiresAt: string;
}

export interface SubClusterCreateData {
  type: string;
  name: string;
  configs: {};
  size: string;
  nodes: number;
}

export interface SubClusterUpdateData {
  subCluster: string;
  size?: string;
  nodes?: number;
}

export type SelfManagedClusterCreateData = {
  type: string;
  name: string;
  size: string;
};
export type SelfManagedClusterUpdateData = {
  subCluster: string;
  size: string;
  nodes: number;
};

export type SubClusterStates = 'disconnected' | 'loading' | 'running';

export interface SuspendTimeData {
  availableIdleTimes: {
    key: number;
    display: string;
  }[];
}

export interface Role {
  id: number;
  role: string;
}

export interface AuthorizedUserRole {
  authorizedRoles: Role[];
  createdAt: string;
  status: string;
  updatedAt: string;
  user: {
    id: string;
    email: string;
    confirmed: boolean;
    fullname: string;
    lastLoginAt: string;
  };
}

export interface AuthUserData {
  authorizedUserRoles: AuthorizedUserRole[];
}

export type ClusterUserRole = 'owner' | 'accountManager' | 'accountAdmin' | 'developer';

export type clusterScalingNode = {
  id: number;
  createdAt: string;
  updatedAt: string;
  instanceID: string;
  scaleOption: string;
  scaleCriteria: string;
  scaleThreshold: null;
  service: ServiceName;
  entries: number;
};

export interface Whitelist {
  active: boolean;
  createdAt: string;
  description: string;
  id: number;
  instanceID: string;
  range: string;
  updatedAt: string;
}

export interface WhiteListData {
  whitelists: Whitelist[];
}

export interface AzureOffers {
  offers: AzureOffer[];
}

export interface AzureOffer {
  id: number;
  email: string;
  plan: string;
}

export interface AzureClusterPipelineData {
  pipelineStatus: {
    id: number;
    pipelineId: number;
    state: string;
    stageName: string;
    name: string;
    url: string;
    email: string;
    configurationName: string;
    network: boolean;
    region: string;
    resourceGroup: string;
    subscriptionID: string;
    tenant: string;
    error: null | string;
    appID: string;
    plan: string;
    password: string;
    incortaImage: string;
    userID: string;
    createdDate: string;
    createdAt: string;
    updatedAt: string;
  };
  states: string[];
}

export type NormalizedAdvancedConfig = Record<
  AdvancedConfigTabDisplayName,
  {
    displayName: AdvancedConfigTabDisplayName;
    id: string;
    tab: Tab;
    name?: string;
    categoriesList: AdvancedConfigCategory[];
    categories: Record<ConfigCategoryType, ConfigCategoryItemConfig[]>;
  }
>;

export type AdvancedConfigTabDisplayName = string;

export type AdvancedConfig = AdvancedConfigResponseConfigItem & {
  displayName: AdvancedConfigTabDisplayName;
  id: string;
};

export type AdvancedConfigsResponse = AdvancedConfigResponseConfigItem[];

export type UpdateAdvancedConfigsRequest = {
  name: string;
  data: {
    configKey: string;
    configValue: any;
  }[];
}[];

type AdvancedConfigResponseConfigItem = {
  tab: Tab;
  name?: string;
  categories: AdvancedConfigCategory[];
};

export type AdvancedConfigCategory = {
  category: ConfigCategoryType;
  config: ConfigCategoryItemConfig[];
};

export type ConfigCategoryItemConfig = {
  key: string;
  tab: Tab;
  category: ConfigCategoryType;
  type: CategoryConfigType;
  internal: boolean;
  requiresRestart: boolean;
  serviceToRestart: ServiceToRestart;
  label: string;
  description: string;
  possibleValues: string[] | number[];
  dependenciesAndingEvaluation: boolean;
  dependencies: { key: string; value: string | number | boolean }[];
  value?: string | number | boolean;
  validators: Validator[];
  structure?: string;
};

export type ConfigCategoryType = ServerCategory | TenantCategory;

type Tab = 'Server' | 'Tenant';
type ServerCategory =
  | 'Clustering'
  | 'SQL Interface'
  | 'Spark Integration'
  | 'Tuning'
  | 'Customizations'
  | 'Diagnostics'
  | 'Data Agent'
  | 'Email'
  | 'Security';
type TenantCategory =
  | 'Security'
  | 'Regional Settings'
  | 'Email'
  | 'Data Loading'
  | 'Integration'
  | 'Export'
  | 'Advanced'
  | 'Tuning'
  | 'Incorta Labs'
  | 'External Visualization Tools'
  | 'MV Assistant';
type CategoryConfigType = 'ENUM' | 'STRING' | 'PASSWORD' | 'TEXT' | 'BOOLEAN' | 'NUMBER' | 'COMPLEX';

export enum ServiceToRestart {
  ALL = 'all',
  LOADER = 'loader',
  ANALYTICS = 'analytics',
  SQLI = 'SQLi',
  NOTEBOOK = 'notebook',
  NONE = 'none',
}

type Validator = RangeValidator | LogginValidator | RangeValidator;
type LogginValidator = {
  type: 'LoggingValidator';
  logging: string;
};
type HostPortValidator = {
  type: 'HostPortValidator';
  'public hosts': string;
};
export type RangeValidator = {
  type: 'RangeValidator';
  min: number;
  max: number;
};
export enum ClusterAction {
  RESTART = 'restart',
  FORCE_RESTART = 'force start',
  STOP = 'stop',
  START = 'start',
  STARTING = 'starting',
}

export enum ServiceStatus {
  PROCESSING = 'Processing',
  STARTING_TENANT = 'Starting Tenant',
  STARTING_TENANTS = 'Starting Tenants',
  IS_STARTING = 'Starting',
  RUNNING = 'Started',
  SLEEPING = 'Sleeping',
  STOPPING = 'Stopping',
  STOPPING_TENANT = 'Stopping Tenant',
  STOPPING_TENANTS = 'Stopping Tenants',
  NOT_RUNNING = 'Stopped',
  ERROR = 'Error',
  STARTUP_ERROR = 'Startup Error',
  NOT_RESPONDING = 'Not Responding',
  TERMINATED_UNEXPECTEDLY = 'Terminated Unexpectedly',
  NA = 'N/A',
  STARTED_WITH_WARNINGS = 'Started with warnings',
  FAILED_TO_START = 'Failed to start',
  CREATING = 'creating',
  DELETING = 'deleting',
}

export type ServiceName = 'analytics' | 'loader' | 'sqli' | 'notebook' | 'analytics-sub-cluster' | 'loader-sub-cluster';
export type ServiceType = 'analyticsStatus' | 'loaderStatus';

export type ObservabilityServiceStatus = {
  statusType: ServiceStatus;
  statusMessage: string;
  warnings: string[];
  error: string;
};

export type ServiceItem<T extends ServiceName> = {
  name: T;
  node_name: string;
  failover: boolean;
  status: ServiceStatus | ObservabilityServiceStatus;
};
export type SubClusterServiceItem<T extends 'analytics-sub-cluster' | 'loader-sub-cluster'> = ServiceItem<T> & {
  subclusterStatus: SubClusterStates;
};
export type ServicesStatusResponse = {
  analyticsServices: ServiceItem<'analytics'>[];
  loaderServices: ServiceItem<'loader'>[];
  sqliService: ServiceItem<'sqli'>;
  notebookService: ServiceItem<'notebook'>;
  analyticsSubClusterServices: SubClusterServiceItem<'analytics-sub-cluster'>[];
  loaderSubClusterServices: SubClusterServiceItem<'loader-sub-cluster'>[];
} & { [key in ServiceType]: ServiceStatus | ObservabilityServiceStatus };

export type ClusterServiceConsumption = {
  id: number;
  type: 'loader' | 'analytics' | 'cmc' | 'subcluster';
  diskSize: number;
  availabeSize: number;
  replica: number;
  instanceID: string;
  subcluster: string;
  running: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
};

export enum clusterConfigurationId {
  CLUSTER_SIZE = 'cluster-size',
  CLUSTER_SERVICES_CONTROL = 'cluster-services-control',
  ANALYTICS_NODE = 'cluster-analytics-nodes',
  LOADER_NODE = 'cluster-loaders-nodes',
  AUTO_SUSPEND = 'cluster-auto-suspend',
  CLUSTER_REGION = 'cluster-region',
  HOME_PAGE = 'display-home-page',
  ON_BOARD = 'display-onboard-page',
  DELTA_SHARING = 'delta-sharing',
  EXTERNAL_BI_TOOLS = 'cluster-external-bi-tools',
  INCORTAX = 'cluster-incorta-x',
  MICROSOFT_SYNAPSE = 'microsoft-synapse',
  DATA_AGENT = 'cluster-data-agent',
  MV_PACKAGES = 'materialized-view-packages',
  COPILOT = 'copilot',
  MLFLOW = 'ml-flow',
  DATASTUDIO = 'datastudio',
}

export type AutoScaleHistoryRecord = {
  service: string;
  replica: number;
  instance_id: string;
  command_id: string;
  rejected_queries: number;
  start_timestamp: string;
  total_queries: number;
};

export enum ScheduledEventEnum {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  SCALE_DOWN = 'scaleDown',
  SCALE_UP = 'scaleUp',
}

export enum ScheduledEventStatusEnum {
  QUEUED = 'QUEUED',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}
export type ScheduledEventBase = {
  id: number;
  startTime: string;
  eventResult: string | null;
  endTime?: string | null;
  status: ScheduledEventStatusEnum;
};

export type ScheduledEvent =
  | (ScheduledEventBase & {
      event: ScheduledEventEnum.CONNECT | ScheduledEventEnum.DISCONNECT;
      eventInformation: null;
    })
  | (ScheduledEventBase & {
      eventResult: string;
      event: ScheduledEventEnum.SCALE_DOWN | ScheduledEventEnum.SCALE_UP;
      eventInformation: { analyticsReplicas?: number; loaderReplicas?: number };
    });

export type ScheduledEventsTypeItem = {
  id: number;
  event: ScheduledEventEnum;
  eventResult: string;
  eventInformation: string; // needs JSON.parse
  status: ScheduledEventStatusEnum;
  instanceID: string;
  userID: string;
  startTime: string | null;
  endTime: string | null;
  frequency: number;
  createdAt: string;
  updatedAt: string;
};
export type ScheduledEventsType = {
  status: number;
  message: ScheduledEventsTypeItem[];
};

export type ScheduledEventsWithInfoParsedItem = {
  id: number;
  event: ScheduledEventEnum;
  eventInformation: {
    analyticsReplicas?: number;
    loaderReplicas?: number;
  };
  status: ScheduledEventStatusEnum;
  instanceID: string;
  eventResult: string;
  userID: string;
  startTime: string | null;
  endTime: string | null;
  frequency: number;
  createdAt: string;
  updatedAt: string;
};

export type isEventExistingType = false | 'View' | 'Edit';

export type scheduledEventFormValuesKeysType =
  | 'eventID'
  | 'event'
  | 'startTime'
  | 'endTime'
  | 'status'
  | 'eventInfoAnalyticsReplicas'
  | 'eventInfoLoaderReplicas'
  | 'eventResult';

export type UpdateScheduledEventEndpointBody = Omit<ScheduledEvent, 'status' | 'id'> & {
  recordID: number;
};
export type CreateScheduledEventEndpointBody = Omit<ScheduledEvent, 'status' | 'id'>;
